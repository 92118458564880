import { Combobox } from '@kobalte/core/combobox';
import { createEffect, createResource, createSignal, Match, Show, Switch } from 'solid-js';
import { ActivityIndicator, DialogContent, Dialog, Input, Picture, DialogTrigger } from '@troon/ui';
import { IconChevronRight, IconMapPin } from '@troon/icons';
import { useNavigate } from '@solidjs/router';
import { createWindowSize } from '@solid-primitives/resize-observer';
import { gql } from '../graphql';
import { cachedQuery } from '../graphql/cached-get';
import type { SearchFacilityFragment, SearchLocationFragment } from '../graphql';
import type { ComponentProps } from 'solid-js';
import type { IGroup, IItem } from '@troon/ui';

type IFacility = IItem<SearchFacilityFragment>;
type IFacilityGroup = IGroup<{ title: string }, SearchFacilityFragment>;
export type ILocation = IItem<SearchLocationFragment>;
type ILocationGroup = IGroup<{ title: string }, SearchLocationFragment>;

type Props = {
	card?: string;
	defaultValue?: string;
	onSelectLocation: (loc: ILocation) => void;
};

const defaultFilter = () => true;

export function SearchLocations(props: Props) {
	const size = createWindowSize();

	return (
		<Show when={size.width > 720} fallback={<SearchLocationsMobile {...props} />}>
			<SearchLocationsLargeScreen {...props} />
		</Show>
	);
}

function SearchLocationsLargeScreen(props: Props) {
	const [input, setInput] = createSignal(props.defaultValue ?? '');
	const [locations] = createResource(() => ({ card: props.card, query: input() }), search);
	const navigate = useNavigate();

	createEffect(() => {
		setInput(props.defaultValue ?? '');
	});

	return (
		<Combobox<IFacility | ILocation, IFacilityGroup | ILocationGroup>
			defaultFilter={defaultFilter}
			onInputChange={setInput}
			onChange={(item) => {
				if (item?.href) {
					navigate(item.href);
					return;
				}
				if (item) {
					props.onSelectLocation(item as ILocation);
				}
			}}
			options={locations.latest ?? []}
			triggerMode="focus"
			modal={false}
			optionTextValue="value"
			optionLabel="displayValue"
			optionValue="value"
			optionDisabled="disabled"
			optionGroupChildren="items"
			placeholder="Where do you want to play?"
			sameWidth
			fitViewport
			closeOnSelection
			selectionBehavior="replace"
			sectionComponent={({ section }) => <Combobox.Section class="px-3">{section.rawValue.title}</Combobox.Section>}
			itemComponent={Item}
		>
			<Combobox.Label class="sr-only">Search by location</Combobox.Label>
			<Combobox.Control>
				<Combobox.Input
					as={Input}
					// @ts-expect-error
					type="search"
					class="rounded-none border-0 border-none ps-8 focus-visible:ring-2 focus-visible:ring-brand-100"
					prefixElement={<IconMapPin class="text-brand" />}
					value={input()}
				/>
			</Combobox.Control>
			<Combobox.Portal>
				<Combobox.Content class="z-50 flex w-full flex-col overflow-y-auto overscroll-contain rounded border border-neutral-300 bg-white py-4 shadow-md">
					{/* TODO: adding enter/exit animations causes this content to never disappear
					   https://github.com/kobaltedev/kobalte/issues/454
				     animate-out fade-out zoom-out slide-out-to-top-16 ui-expanded:animate-in ui-expanded:fade-in ui-expanded:zoom-in ui-expanded:slide-in-from-top-16
					*/}
					<Show when={locations.loading}>
						<ActivityIndicator />
					</Show>
					<Combobox.Listbox />
				</Combobox.Content>
			</Combobox.Portal>
		</Combobox>
	);
}

function SearchLocationsMobile(props: Props) {
	const [input, setInput] = createSignal(props.defaultValue ?? '');
	const [locations] = createResource(() => ({ card: props.card, query: input() }), search);
	const navigate = useNavigate();
	const [open, setOpen] = createSignal(false);

	createEffect(() => {
		setInput(props.defaultValue ?? '');
	});

	return (
		<Dialog key="search" open={open()} onOpenChange={setOpen}>
			<DialogTrigger
				appearance="transparent-current"
				class="flex w-full min-w-32 grow cursor-pointer flex-nowrap items-center justify-start gap-x-2 whitespace-nowrap rounded py-3 ps-1 font-medium normal-case outline-none transition-all duration-200 hover:bg-brand-100  active:bg-brand-100 disabled:bg-transparent disabled:opacity-50 aria-disabled:opacity-50 ui-highlighted:bg-neutral-100"
			>
				<IconMapPin class="text-brand" />
				{input() || <span class="text-neutral-700">Where do you want to play?</span>}
			</DialogTrigger>
			<DialogContent header={<span class="sr-only">Search by location</span>} headerLevel="h3" autoHeight={false}>
				<Combobox<IFacility | ILocation, IFacilityGroup | ILocationGroup>
					defaultFilter={defaultFilter}
					onInputChange={setInput}
					onChange={(item) => {
						if (item?.href) {
							navigate(item.href);
							return;
						}
						if (item) {
							props.onSelectLocation(item as ILocation);
						}
						setOpen(false);
					}}
					options={locations.latest ?? []}
					triggerMode="focus"
					modal={false}
					optionTextValue="value"
					optionLabel="displayValue"
					optionValue="value"
					optionDisabled="disabled"
					optionGroupChildren="items"
					placeholder="Where do you want to play?"
					open
					defaultOpen
					selectionBehavior="replace"
					class="flex flex-col gap-4"
					sectionComponent={({ section }) => <Combobox.Section class="px-3">{section.rawValue.title}</Combobox.Section>}
					itemComponent={Item}
				>
					<Combobox.Label class="sr-only">Search by location</Combobox.Label>
					<Combobox.Control>
						<Combobox.Input
							as={Input}
							// @ts-expect-error
							type="search"
							class="ps-8"
							prefixElement={<IconMapPin class="text-brand" />}
							value={input()}
						/>
					</Combobox.Control>
					<Show when={locations.loading}>
						<ActivityIndicator />
					</Show>
					<Combobox.Listbox />
				</Combobox>
			</DialogContent>
		</Dialog>
	);
}

const Item: ComponentProps<typeof Combobox<IFacility | ILocation>>['itemComponent'] = (props) => {
	return (
		<Combobox.Item
			item={props.item}
			class="flex w-full min-w-32 grow cursor-pointer flex-nowrap items-center justify-start gap-x-2 whitespace-nowrap rounded p-3 font-medium outline-none transition-all duration-200 hover:bg-brand-100 active:bg-brand-100 disabled:bg-transparent disabled:opacity-50 aria-disabled:opacity-50 ui-highlighted:bg-neutral-100"
		>
			<Switch>
				<Match when={props.item.rawValue.href}>
					<FacilityItem {...(props.item.rawValue as IFacility)} />
				</Match>
				<Match when={!props.item.rawValue.href}>
					<LocationItem {...(props.item.rawValue as ILocation)} />
				</Match>
			</Switch>
		</Combobox.Item>
	);
};

function FacilityItem(props: IFacility) {
	return (
		<>
			<div class="aspect-square size-16 overflow-hidden rounded bg-neutral-500">
				<Show when={props.metadata?.hero?.url}>
					<Picture
						src={props.metadata!.hero!.url}
						sizes={[
							[256, 256],
							[128, 128],
						]}
						alt=""
						width={128}
						height={128}
						class="aspect-square"
					/>
				</Show>
			</div>
			<div class="flex grow flex-col items-start justify-center">
				<Combobox.ItemLabel class="font-semibold">{props.name}</Combobox.ItemLabel>
				<Show when={props.metadata?.address?.city || props.metadata?.address?.state}>
					<p class="text-sm text-neutral-700">
						<Show when={props.metadata?.address?.city}>{props.metadata?.address?.city}, </Show>
						{props.metadata?.address?.state}
					</p>
				</Show>
			</div>
			<IconChevronRight class="self-center text-brand-600" />
		</>
	);
}

function LocationItem(props: ILocation) {
	return (
		<>
			<div class="flex aspect-square size-16 items-center justify-center overflow-hidden rounded bg-brand-100 text-brand">
				<IconMapPin />
			</div>
			<div class="flex grow flex-col items-start justify-center">
				<Combobox.ItemLabel class="font-semibold">
					{props.place}, {props.region}
				</Combobox.ItemLabel>
			</div>
		</>
	);
}

type Results = Array<ILocationGroup | IFacilityGroup>;

async function search(opts: { card?: string; query: string }) {
	const fallback = [
		{
			title: 'Locations',
			items: getPopularLocations(opts.card),
		},
	] satisfies Results;

	if (opts.query.length < 3) {
		return fallback;
	}

	const res = await searchFacilities({ query: opts.query });
	if (!res?.search) {
		return fallback;
	}

	const items: Results = [];

	if (res.search.locations.length) {
		items.push({
			title: 'Locations',
			items: (res.search.locations as Array<SearchLocationFragment>).slice(0, 2).map(mapLocation),
		} satisfies ILocationGroup);
	}

	if (res.search.facilities.length) {
		items.push({
			title: 'Courses',
			items: (res.search.facilities as Array<SearchFacilityFragment>).map(
				(facility) =>
					({
						...facility,
						value: facility.slug,
						href: `/course/${facility.slug}/reserve-tee-time`,
					}) satisfies IFacility,
			),
		} satisfies IFacilityGroup);
	}

	return items;
}

function mapLocation(loc: SearchLocationFragment) {
	return {
		value: `ll-${loc.latitude}-${loc.longitude}`.replace(/[^\w]+/g, '-'),
		displayValue: [loc.place, loc.region].join(', '),
		...loc,
	} satisfies ILocation;
}

const popularLocations: Record<string, Array<SearchLocationFragment>> = {
	az: [
		{ country: 'USA', place: 'Scottsdale', region: 'AZ', latitude: 33.4956, longitude: -111.9182 },
		{ country: 'USA', place: 'Tucson', region: 'AZ', latitude: 32.2545, longitude: -110.984 },
	],
	ny: [{ country: 'USA', place: 'New York', region: 'NY', latitude: 40.715, longitude: -74.00715 }],
	fl: [
		{ country: 'USA', place: 'Naples', region: 'FL', latitude: 26.14212, longitude: -81.79419 },
		{ country: 'USA', place: 'Orlando', region: 'FL', latitude: 28.5351, longitude: -81.38558 },
		{ country: 'USA', place: 'Tampa', region: 'FL', latitude: 27.9592, longitude: -82.468 },
	],
	tucson: [{ country: 'USA', place: 'Tucson', region: 'AZ', latitude: 32.2545, longitude: -110.984 }],
	hi: [
		{ country: 'USA', place: 'Maui', region: 'HI', latitude: 20.79714, longitude: -156.3325 },
		{ country: 'USA', place: 'Honolulu', region: 'HI', latitude: 21.310742, longitude: -157.85366 },
	],
	westcoast: [
		{ country: 'USA', place: 'Palm Springs', region: 'CA', latitude: 33.8312, longitude: -116.5414 },
		{ country: 'USA', place: 'Bend', region: 'OR', latitude: 44.05863, longitude: -121.31842 },
		{ country: 'USA', place: 'Los Angeles', region: 'CA', latitude: 34.05417, longitude: -118.27307 },
		{ country: 'USA', place: 'San Francisco', region: 'CA', latitude: 37.7752, longitude: -122.436366 },
	],
	northeast: [
		{ country: 'USA', place: 'New York', region: 'NY', latitude: 40.715, longitude: -74.00715 },
		{ country: 'USA', place: 'Atlantic City', region: 'NJ', latitude: 39.364792, longitude: -74.42312 },
	],
	southeast: [
		{ country: 'USA', place: 'Naples', region: 'FL', latitude: 26.14212, longitude: -81.79419 },
		{ country: 'USA', place: 'Orlando', region: 'FL', latitude: 28.5351, longitude: -81.38558 },
	],
	ga: [{ country: 'USA', place: 'Atlanta', region: 'GA', latitude: 33.75107, longitude: -84.3858 }],
};
const popularLocationsFallback: Array<SearchLocationFragment> = [
	{ country: 'USA', place: 'Scottsdale', region: 'AZ', latitude: 33.4956, longitude: -111.9182 },
	{ country: 'USA', place: 'New York', region: 'NY', latitude: 40.715, longitude: -74.00715 },
	{ country: 'USA', place: 'Palm Springs', region: 'CA', latitude: 33.8312, longitude: -116.5414 },
	{ country: 'USA', place: 'Naples', region: 'FL', latitude: 26.14212, longitude: -81.79419 },
];

export function getPopularLocations(card?: string) {
	return (card ? (popularLocations[card] ?? popularLocationsFallback) : popularLocationsFallback).map(mapLocation);
}

const searchQuery = gql(`
query searchFacilities($query: String!) {
	search: searchFacilitiesV2(query: $query, includeGeocoded: false, includeLocations: true) {
		facilities {
			...SearchFacility
		}
		locations {
			...SearchLocation
		}
	}
}
fragment SearchFacility on Facility {
	slug
	name
	metadata {
		address {
			city
			state
		}
		hero {
			url
		}
	}
}
fragment SearchLocation on Location {
	latitude
	longitude
	region
	country
	place
}
`);

const searchFacilities = cachedQuery(searchQuery);
